@import url('https://fonts.googleapis.com/css2?family=McLaren&family=Outfit:wght@100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Outfit;
  scroll-behavior: smooth;
}

body{
  min-height: 100vh;
}

a{
  text-decoration: none;
  color: inherit;
}

.app{
  width: 80%;
  margin: auto;
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}