.food-display{
    margin-top: 30px;
}

.food-display h2{
    font-size: max(2vw,24px);
    font-weight: 600;
}

.food-display-list{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    margin-top: 30px;
    gap: 30px;
    row-gap: 50px;
}

